import { useUIStore } from "@/store/ui";
// import $cookies from "vue-cookies";
// import * as Sentry from "@sentry/vue";

import { useSendEventToNative } from "@/composables/useSendEventToNative";

export default class ErrorService {
  constructor() {
    // this.initHandler();
  }

  static onError(error) {
    const uiStore = useUIStore();

    // const store = useUIStore();
    const response = error.response;

    uiStore.set_response_dialog_icon("mdi-close");
    uiStore.set_response_dialog_title("İşlem başarısız!");
    uiStore.set_response_dialog_message(
      response.data.message ?? "İşlem başarısız!"
    );
    uiStore.set_response_dialog_open(true);

    if (response?.status >= 400 && response?.status < 405) {
      this.sentryLogEngine();
      if (response.status == 401) {
        // $cookies.remove("access_token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        useSendEventToNative({ event: "logoutExpire" });
        // window.location.href = "/login";
      }
      // return false;
    }

    if (response?.status >= 422) {
    }
    // Send Error to Log Engine e.g LogRocket
    // ErrorService.logRocketLogEngine(error);
  }

  static onWarn(error) {
    // Send Error to Log Engine e.g LogRocket
    this.logRocketLogEngine(error);
  }

  static onInfo(error) {
    // You can handle this differently
    this.sentryLogEngine(error);
  }

  static onDebug(error) {
    const response = error.response;
    if (response && response.status >= 400 && response.status < 405) {
      // You can handle this differently
      this.sentryLogEngine(error);
      return false;
    }
    // Send Error to Log Engine e.g LogRocket
    // this.logRocketLogEngine(error);
  }

  static initHandler() {
    const scope = this;
    window.onerror = (message, url, lineNo, columnNo, error) => {
      if (error) {
        scope.onError(error);
      }
    };
  }

  static displayErrorAlert(message) {
    // Swal.fire({
    //   title: "Error!",
    //   text: message,
    //   icon: "error",
    // });
  }

  static logRocketLogEngine(error) {
    // Implement LogRocket Engine here
    console.log(error, "LogRocket");
  }

  static sentryLogEngine(error) {
    // Implement Sentry Engine here
    console.log(error, "Sentry");
  }
}
