import { createI18n } from "vue-i18n";

const messages = {
  tr: {
    locale: {
      en: "İngilizce",
      tr: "Türkçe",
    },
    currency: "TL",
    smallCard: {
      title: "Güncel Faturam",
      subtitle: "Ödenmemiş borcunuz bulunmamaktadır.",
      btnText: "Detay",
    },
    statisticCard: {
      title: "Paketim",
      subtitle: "Yenilenme Tarihi",
    },
    footerBar: {
      home: "Anasayfa",
      packages: "Paketler",
      operations: "İşlemler",
      support: "Destek",
    },
    usageDetails: {
      comeBack: "Kullanım Detayı",
      selectByTypeAll: "Tümü",
      headerOfTable: {
        date: "Tarih",
        number: "Numara",
        quantity: "Miktar",
        price: "Fiyat",
      },
      selectByMonth: {
        january: "Ocak",
        february: "Şubat",
        march: "Mart",
        april: "Nisan",
        may: "Mayıs",
        june: "Haziran",
        july: "Temmuz",
        august: "Ağustos",
        september: "Eylül",
        october: "Ekim",
        november: "Kasım",
        december: "Aralık",
      },
    },
    packages: {
      tabs: {
        mobileLine: "Mobil Hat",
        mobileInternet: "Mobil İnternet",
        mobileCall: "Konuşma",
      },
    },

    support: {
      addTicket: {
        ticketType: "Destek Tipi",
        ticketIssue: "Destek Konusu",
        name: "Ad",
        surname: "Soyad",
        email: "E-posta",
        phone: "Cep Numarası",
        message: "Mesajınız",
        submitBtn: "Kaydet",
        cancelBtn: "İptal",
        dialog: {
          stepper: {
            firstStep: {
              firstInfo: `1. Fesih Talep Formu'nu <span class="font-weight-bold text-decoration-underline">aşağıdaki linkten</span> indirin`,
              secondInfo:
                '2. Doldurduğunuz formu 2.adımdaki <span class="font-weight-bold">Belge Yükle</span> bölümünden yükleyin.',
            },
          },
          nextBtn: "İleri",
          previousBtn: "Geri",
          submitBtn: "Yükle",
        },
      },
    },
  },
  en: {
    locale: {
      en: "English",
      tr: "Turkish",
    },
    currency: "USD", // İngilizce çevirisi
    smallCard: {
      title: "Current Invoice", // İngilizce çevirisi
      subtitle: "You have an unpaid debt.", // İngilizce çevirisi
      btnText: "Details", // İngilizce çevirisi
    },
    statisticCard: {
      title: "My Package", // İngilizce çevirisi
      subtitle: "Renewal Date", // İngilizce çevirisi
    },
    footerBar: {
      home: "Home", // İngilizce çevirisi
      packages: "Packages", // İngilizce çevirisi
      operations: "Operations", // İngilizce çevirisi
      support: "Support", // İngilizce çevirisi
    },
    usageDetails: {
      comeBack: "Usage Details", // İngilizce çevirisi
      selectByTypeAll: "All", // İngilizce çevirisi
      headerOfTable: {
        date: "Date", // İngilizce çevirisi
        number: "Number", // İngilizce çevirisi
        quantity: "Quantity", // İngilizce çevirisi
        price: "Price", // İngilizce çevirisi
      },
      selectByMonth: {
        january: "January", // İngilizce çevirisi
        february: "February", // İngilizce çevirisi
        march: "March", // İngilizce çevirisi
        april: "April", // İngilizce çevirisi
        may: "May", // İngilizce çevirisi
        june: "June", // İngilizce çevirisi
        july: "July", // İngilizce çevirisi
        august: "August", // İngilizce çevirisi
        september: "September", // İngilizce çevirisi
        october: "October", // İngilizce çevirisi
        november: "November", // İngilizce çevirisi
        december: "December", // İngilizce çevirisi
      },
    },
  },
};

export default createI18n({
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  warnHtmlMessage: false,
  legacy: false,
  globalInjection: true,
  messages,
});
