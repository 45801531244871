import { defineStore } from "pinia";

import ErrorService from "@/services/error-service";

const {
  SET_LOADING,
  SET_ERROR,
  SET_IS_ERROR,
  SET_SUPPORT_DIALOG,
  SET_IS_COMEBACK_BTN,
  SET_APP_DIALOG_OPEN,
  SET_APP_DIALOG_TITLE,
  SET_APP_DIALOG_MESSAGE,
  SET_APP_DIALOG_ICON,
  SET_APP_DIALOG_ACTIONS,
  SET_RESPONSE_DIALOG_OPEN,
  SET_RESPONSE_DIALOG_TITLE,
  SET_RESPONSE_DIALOG_MESSAGE,
  SET_RESPONSE_DIALOG_ICON,
  SET_RESPONSE_DIALOG_ACTIONS,
  SET_OPEN_PAYMENT_IFRAME,
  SET_OVERLAY_LOADING,
  SET_OPEN_ADD_CREDIT_CARD_IFRAME,
} = {
  SET_LOADING: "set_loading",
  SET_ERROR: "set_error",
  SET_IS_ERROR: "set_is_error",
  SET_SUPPORT_DIALOG: "set_support_dialog",
  SET_IS_COMEBACK_BTN: "set_is_comeback_btn",
  SET_APP_DIALOG_OPEN: "set_app_dialog_open",
  SET_APP_DIALOG_TITLE: "set_app_dialog_title",
  SET_APP_DIALOG_MESSAGE: "set_app_dialog_message",
  SET_APP_DIALOG_ICON: "set_app_dialog_icon",
  SET_APP_DIALOG_ACTIONS: "set_app_dialog_actions",
  SET_OPEN_PAYMENT_IFRAME: "set_open_payment_iframe",
  SET_RESPONSE_DIALOG_OPEN: "set_response_dialog_open",
  SET_RESPONSE_DIALOG_TITLE: "set_response_dialog_title",
  SET_RESPONSE_DIALOG_MESSAGE: "set_response_dialog_message",
  SET_RESPONSE_DIALOG_ICON: "set_response_dialog_icon",
  SET_RESPONSE_DIALOG_ACTIONS: "set_response_dialog_actions",
  SET_OVERLAY_LOADING: "set_overlay_loading",
  SET_OPEN_ADD_CREDIT_CARD_IFRAME: "set_open_add_credit_card_iframe",
};

export const useUIStore = defineStore("ui", {
  state: () => ({
    error: {},
    is_error: false,
    support_dialog: false,
    is_comeback_btn: false,

    app_dialog_open: false,
    app_dialog_title: "",
    app_dialog_message: "",
    app_dialog_actions: false,
    app_dialog_icon: null,

    response_dialog_open: false,
    response_dialog_title: "",
    response_dialog_message: "",
    response_dialog_actions: false,
    response_dialog_icon: "mdi-check",

    open_payment_iframe: false,
    open_add_credit_card_iframe: false,

    loading_for_credit_card: false,

    loading: false,

    overlay_loading: false,
  }),

  getters: {
    get_loading() {
      return this.loading;
    },

    get_open_payment_iframe() {
      return this.open_payment_iframe;
    },
    get_open_add_credit_card_iframe() {
      return this.open_add_credit_card_iframe;
    },
    get_is_error() {
      return this.is_error;
    },
    get_error() {
      return this.error;
    },
    get_support_dialog() {
      return this.support_dialog;
    },
    get_is_comeback_btn() {
      return this.is_comeback_btn;
    },

    get_app_dialog_open() {
      return this.app_dialog_open;
    },
    get_app_dialog_message() {
      return this.app_dialog_message;
    },
    get_app_dialog_title() {
      return this.app_dialog_title;
    },
    get_app_dialog_icon() {
      return this.app_dialog_icon;
    },

    get_app_dialog_actions() {
      return this.app_dialog_actions;
    },

    get_response_dialog_open() {
      return this.response_dialog_open;
    },
    get_response_dialog_message() {
      return this.response_dialog_message;
    },
    get_response_dialog_title() {
      return this.response_dialog_title;
    },
    get_response_dialog_icon() {
      return this.response_dialog_icon;
    },

    get_response_dialog_actions() {
      return this.response_dialog_actions;
    },

    get_loading_for_credit_card() {
      return this.loading_for_credit_card;
    },
  },

  actions: {
    [SET_LOADING](value) {
      this.loading = value;
    },

    [SET_ERROR](error) {
      this.error = error.response;

      ErrorService.onError(error);
    },

    [SET_IS_ERROR](value) {
      this.is_error = value;
    },

    [SET_SUPPORT_DIALOG](value) {
      this.support_dialog = value;
    },

    [SET_IS_COMEBACK_BTN](value) {
      this.is_comeback_btn = value;
    },

    [SET_APP_DIALOG_OPEN](value) {
      this.app_dialog_open = value;
    },
    [SET_APP_DIALOG_ICON](value) {
      this.app_dialog_icon = value;
    },
    [SET_APP_DIALOG_TITLE](value) {
      this.app_dialog_title = value;
    },

    [SET_APP_DIALOG_MESSAGE](value) {
      this.app_dialog_message = value;
    },

    [SET_APP_DIALOG_ACTIONS](value) {
      this.app_dialog_actions = value;
    },

    [SET_OPEN_PAYMENT_IFRAME](value) {
      this.open_payment_iframe = value;
    },

    [SET_RESPONSE_DIALOG_OPEN](value) {
      this.response_dialog_open = value;
    },
    [SET_RESPONSE_DIALOG_ICON](value) {
      this.response_dialog_icon = value;
    },
    [SET_RESPONSE_DIALOG_TITLE](value) {
      this.response_dialog_title = value;
    },

    [SET_RESPONSE_DIALOG_MESSAGE](value) {
      this.response_dialog_message = value;
    },

    [SET_RESPONSE_DIALOG_ACTIONS](value) {
      this.response_dialog_actions = value;
    },

    [SET_OPEN_ADD_CREDIT_CARD_IFRAME](value) {
      this.open_add_credit_card_iframe = value;
    },

    [SET_OVERLAY_LOADING](value) {
      this.overlay_loading = value;
    },

    set_loading_for_credit_card(value) {
      this.loading_for_credit_card = value;
    },
  },
});
