<script setup>
import { onMounted, onUnmounted } from "vue";
import { useAuthStore } from "@/store/auth";

import { useSendEventToNative } from "./composables/useSendEventToNative";

import { useRouter } from "vue-router";
const router = useRouter();
const store = useAuthStore();

const messageEventListener = (event) => {
  if (event.origin == import.meta.env.VITE_IFRAME_REDIRECT_URL_ORIGIN) {
    const data = JSON.parse(event?.data);
    // Gelen veriye göre işlem yap
    useSendEventToNative({ type: data?.type, data: data?.data })
    router.push({ name: data?.type });
  }
};

onMounted(async () => {
  const user = localStorage.getItem("user");
  // const token = $cookie.get("access_token");
  const access_token = localStorage.getItem("access_token");

  if (access_token) {
    store.set_user_info({ user_info: JSON.parse(user), access_token });

    window.addEventListener("message", messageEventListener);
  }
});

onUnmounted(() => {
  window.removeEventListener("message", messageEventListener);
});
</script>

<template>
  <router-view></router-view>
</template>

<style>

</style>
