// Utilities
import { defineStore } from "pinia";
// import $cookie from "vue-cookies";

// import { useUIStore } from "@/store/ui";
import axios_instance from "@/lib/axios";

const { LOGIN, FETCH_ACCESS_TOKEN, SET_USER_INFO, FETCH_USER_INFO } = {
  LOGIN: "login",
  FETCH_ACCESS_TOKEN: "fetch_access_token",
  FETCH_USER_INFO: "fetch_user_info",
  SET_USER_INFO: "set_user_info",
};

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: null,
    user: {},
  }),

  getters: {
    get_token() {
      return this.token;
    },
    get_user() {
      return this.user;
    },
  },

  actions: {
    [SET_USER_INFO](user_data) {
      this.user = user_data.user_info;
      this.token = user_data.access_token;
    },

    async [FETCH_USER_INFO]() {
      this.user = await axios_instance.get("/user");
      return this.user;
    },

    async [LOGIN](login_form) {
      return axios_instance.post("/login", login_form);
    },

    async [FETCH_ACCESS_TOKEN](auth_code) {
      const data = await axios_instance.post("/access-token", auth_code, {
        params: { mobile: 1 },
      });

      // $cookie.set("access_token", data.access_token);
      localStorage.setItem("access_token", data.access_token)

      this.token = data.access_token;
      return data;
    },
  },
});
