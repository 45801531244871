/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from "@/plugins/vuetify";

import pinia from "@/store";

import router from "@/router";
import i18n from "@/i18n";
// import VuePaycard from "vue-paycard";

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

export function registerPlugins(app) {
  app.use(vuetify).use(pinia).use(router).use(i18n);

  // if (import.meta.env.MODE == "production") {
  //   Sentry.init({
  //     app,
  //     dsn: "https://e091ad09af3cc67fd17be7a6f2581d37@o4505605660278784.ingest.us.sentry.io/4506976767901696", // Sentry DSN
  //     integrations: [
  //       new BrowserTracing({
  //         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //         tracingOrigins: ["localhost", /^\//],
  //       }),
  //     ],
  //     // Diğer Sentry seçenekleri...
  //     tracesSampleRate: 1.0,
  //   });
  // }
}
