// Components
import App from "./App.vue";

// Composables
import { createApp } from "vue";

// Plugins
import { registerPlugins } from "@/plugins";

const app = createApp(App);

registerPlugins(app);

if (import.meta.env.NODE_ENV === "production") {
  app.config.warnHandler = () => {}; // Uyarıları engellemek
  app.config.errorHandler = () => {}; // Hataları engellemek
}

app.mount("#app");
