// import { useUIStore } from "@/store/ui";
import axios from "axios";
// import $cookie from "vue-cookies";

// const uiStore = useUIStore();

const axios_instance = axios.create({
  withCredentials: true,
  baseURL:
    import.meta.env.MODE == "development" ? import.meta.env.VITE_URI : "/api",
});

axios_instance.interceptors.request.use(
  (config) => {
    // const token = $cookie.get("access_token");
    const token = localStorage.getItem("access_token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios_instance.interceptors.response.use((response) => {
  // uiStore.set_success_snackbar(true);

  return response.data;
});

export default axios_instance;
