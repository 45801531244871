/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { VBtn } from "vuetify/components/VBtn";
import { VCard } from "vuetify/components/VCard";
import { VTextarea } from "vuetify/components/VTextarea";
// import { VDataTable } from "vuetify/labs/VDataTable";

// Composables
import { createVuetify } from "vuetify";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    VTextarea,
    VBtn,
    VCard,
  },
  defaults: {
    VBtn: {
      class: "text-capitalize text-caption",
      color: "secondary",
      size: "default",
    },
    VCard: {
      border: true,
      size: "default",
      elevation: 0,
    },
    VSelect: {
      variant: "outlined",
    },
    VTextarea: {
      variant: "outlined",
    },
  },

  theme: {
    themes: {
      light: {
        colors: {
          primary: "#AAC7F1",
          secondary: "#0b5ed7",
          error: "#EA5455",
          success: "#54ac68",
          textColor: "#4B465C",
        },
      },
    },
  },

  directives: {
    "no-emojis": {
      bind(el) {
        el.addEventListener("input", (event) => {
          event.target.value = event.target.value.replace(
            /[\u{1F600}-\u{1F6FF}]/gu,
            ""
          ); // Emoji aralığını kaldır
          const inputEvent = new Event("input", { bubbles: true });
          event.target.dispatchEvent(inputEvent);
        });
      },
    },
  },
});
