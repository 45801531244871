// Composables
import { createRouter, createWebHistory, RouterView } from "vue-router";
import { useUIStore } from "@/store/ui";

// import $cookie from "vue-cookies";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/default/Default.vue"),
    meta: {
      isAuthRequired: true,
    },
    children: [
      {
        path: "",
        name: "HomeMain",
        component: RouterView,
        meta: {
          routerNavbar: "Home",
        },
        children: [
          {
            name: "Home",
            path: "",
            component: () => import("@/views/Home"),
          },
          {
            path: "usage-details",
            name: "UsageDetails",
            component: () => import("@/views/Home/UsageDetails.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
        ],
      },
      {
        path: "packages",
        component: RouterView,
        meta: {
          routerNavbar: "Packages",
        },
        children: [
          {
            path: "",
            name: "Packages",
            component: () => import("@/views/Package/index.vue"),
          },
          {
            path: ":packageId",
            name: "PackageDetails",
            component: () => import("@/views/Package/PackageDetails.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "membership-details",
            name: "MembershipDetails",
            component: () => import("@/views/Package/MembershipDetails.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "basket",
            name: "Basket",
            component: () => import("@/views/Package/Basket.vue"),

            meta: {
              isComeBackBtn: true,
            },
          },
        ],
      },
      {
        path: "operations",
        component: RouterView,
        name: "OperationsMain",
        meta: {
          routerNavbar: "Operations",
        },
        children: [
          {
            path: "",
            name: "Operations",
            component: () => import("@/views/Operations"),
          },
          {
            path: "notification-service",
            name: "NotificationService",
            component: () =>
              import("@/views/Operations/NotificationService.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "notifications",
            name: "Notifications",
            component: () => import("@/views/Operations/Notifications.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "change-password",
            name: "ChangePassword",
            component: () => import("@/views/Operations/ChangePassword.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "safe-internet",
            name: "SafeInternet",
            component: () => import("@/views/Operations/SafeInternet.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "safe-internet-info",
            name: "SafeInternetInfo",
            component: () => import("@/views/Operations/SafeInternetInfo.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "payment",
            name: "Payment",
            component: () => import("@/views/Operations/Payment.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "registered-credit-card",
            name: "RegisteredCreditCard",
            component: () =>
              import("@/views/Operations/RegisteredCreditCard.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "invoice",
            name: "InvoiceList",
            component: () => import("@/views/Operations/InvoiceList.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: ":invoiceId",
            name: "InvoiceDetail",
            component: () => import("@/views/Operations/InvoiceDetail.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: "call-forwarding",
            name: "CallForwarding",
            component: () => import("@/views/Operations/CallForwarding.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
        ],
      },
      {
        path: "support",
        name: "SupportMain",
        component: RouterView,
        meta: {
          routerNavbar: "Support",
        },
        children: [
          {
            name: "Support",
            path: "",
            component: () => import("@/views/Support"),
          },
          {
            path: "add-ticket",
            name: "SupportAddTicket",
            component: () => import("@/views/Support/AddTicket.vue"),
            meta: {
              isComeBackBtn: true,
            },
          },
          {
            path: ":ticket_no",
            name: "TicketMessages",
            meta: {
              isComeBackBtn: true,
            },
            component: () => import("@/views/Support/TicketMessages.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/layouts/login/Login.vue"),
    meta: {
      isAuthRequired: false,
    },
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("@/views/Login.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // const token = $cookie.get("access_token");
  const token = localStorage.getItem("access_token");

  if (to.matched.some((record) => record.meta.isAuthRequired)) {
    if (!token) next({ name: "Login" });
    else next();
  } else {
    if (!token) next();
    else next({ name: "Home" });
  }
});

router.beforeEach((to, from, next) => {
  const uiStore = useUIStore();

  if (to.meta.isComeBackBtn) {
    uiStore.set_is_comeback_btn(true);
  } else {
    uiStore.set_is_comeback_btn(false);
  }
  next();
});

export default router;
